import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import Constants from 'expo-constants';
import { getFirestore, doc, updateDoc, increment } from 'firebase/firestore'; // Add increment
import { getAuth } from 'firebase/auth';
import { Platform } from 'react-native';

const db = getFirestore();

export const getAndStorePushToken = async () => {
  try {
    // Request notification permissions if not granted
    const { status } = await Notifications.getPermissionsAsync();
    let notificationsEnabled = status === 'granted';

    if (!notificationsEnabled) {
      const { status: newStatus } = await Notifications.requestPermissionsAsync();
      notificationsEnabled = newStatus === 'granted';
      if (!notificationsEnabled) return; // Exit if permission is still not granted
    }

    // Ensure that the device is physical (required for push notifications)
    if (!Device.isDevice) {
      console.log('Must use a physical device for push notifications');
      return;
    }

    // Configure Android notification channel
    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }

    // Get the Expo push token
    const pushToken = (await Notifications.getExpoPushTokenAsync({
      projectId: Constants.expoConfig?.extra?.eas.projectId,
    })).data;

    // Get current user ID from Firebase Auth
    const userId = getAuth().currentUser?.uid;

    // Get the device brand (manufacturer) and model
    const deviceBrand = Device.manufacturer;
    const deviceModel = Device.modelName;
    console.log('Device Brand:', deviceBrand); // Log the device brand
    console.log('Device Model:', deviceModel); // Log the device model

    if (userId && pushToken) {
      const userDocRef = doc(db, 'users', userId);
      await updateDoc(userDocRef, {
        expoPushToken: pushToken,
        notification: notificationsEnabled, // Stores true if granted, false if not
        deviceBrand: deviceBrand, // Store the device brand in Firebase
        deviceModel: deviceModel, // Store the device model in Firebase
        loginCounterTest: increment(1), // Increment login counter
      });
      console.log('Push token, notification preference, device brand, model, and loginCounter saved in Firebase');
    }
  } catch (error) {
    console.error('Error storing push token, notification status, device info, or loginCounter:', error);
  }
};
