// profile last code 


import React, { useState, useEffect, useCallback } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  StyleSheet,
  Pressable,
  ActivityIndicator,
  ScrollView,
  Linking, 
  Alert,
  Platform,
  Image,

} from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useNavigation } from "@react-navigation/native";
import { Avatar } from "react-native-elements";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { database, auth } from "../componenets/firebase";
import { getAuth } from "firebase/auth";

 
import * as ImagePicker from 'expo-image-picker';

 import * as ImageManipulator from 'expo-image-manipulator';
 import * as FileSystem from 'expo-file-system';



import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { MaterialIcons } from "@expo/vector-icons";
import AccountSettings from '../screens/AccountSettings';
import NotificationSettings from '../screens/NotificationSettings';
import { useUser } from '../navigation/UserContext'; // Adjust the path according to your project structure

//import { SaveFormat } from 'expo-image-manipulator';




const ProfileTestStack = createNativeStackNavigator();

const Profile = () => (
  <ProfileTestStack.Navigator
    screenOptions={{
      headerShown: true,
    }}
  >
    <ProfileTestStack.Screen
      name="ProfileTestScreen"
      component={ProfileTestScreen}
      options={({ navigation }) => ({
        headerTitle: () => (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <TouchableOpacity
              onPress={() => navigation.goBack()}
              style={{ marginRight: 10 }}
            >
              <MaterialIcons name="arrow-back" size={24} color="white" />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <Text style={{ color: "white", fontWeight: "bold", fontSize: 18 }}>
                Go back
              </Text>
            </TouchableOpacity>
          </View>
        ),
        headerStyle: {
          backgroundColor: "#4361ee",
        },
        headerTintColor: "white",
        headerTitleStyle: {
          fontWeight: "bold",
          color: "white",
        },
        headerRight: () => (
          <View style={{ marginRight: 10 }}>
            <Avatar
              rounded
              size="medium"
              source={{
                uri: "https://i.pravatar.cc/300",
              }}
              onPress={() => navigation.openDrawer()}
              activeOpacity={0.1}
            />
            <TouchableOpacity onPress={() => navigation.openDrawer()}>
              <Text style={{ color: "red" }}> User! </Text>
            </TouchableOpacity>
          </View>
        ),
      })}
    />

    <ProfileTestStack.Screen
      name="AccountSettings"
      component={AccountSettings}
      options={{
        headerTitle: 'Account Settings',
        headerStyle: {
          backgroundColor: "#4361ee",
        },
        headerTintColor: "white",
        headerTitleStyle: {
          fontWeight: "bold",
          color: "white",
        },
      }}
    />
    <ProfileTestStack.Screen
      name="NotificationSettings"
      component={NotificationSettings}
      options={{
        headerTitle: 'Notification Settings',
        headerStyle: {
          backgroundColor: "#4361ee",
        },
        headerTintColor: "white",
        headerTitleStyle: {
          fontWeight: "bold",
          color: "white",
        },
      }}
    />


  </ProfileTestStack.Navigator>
);


const ProfileTestScreen = () => {
  const navigation = useNavigation();
  const [userName, setUserName] = useState("");
  const [originalUserName, setOriginalUserName] = useState("");
  const [email, setEmail] = useState("");
  const [originalEmail, setOriginalEmail] = useState("");
  const [editing, setEditing] = useState(false);
  const [avatarUriURL, setAvatarUriURL] = useState("");
  const [selectedImageUri, setSelectedImageUri] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //const { globalUserId, setGlobalUserData } = useUser(); // Access globalUserId and function to update global state
  const { user, globalUserId, setUser } = useUser();


  const [originalSize, setOriginalSize] = useState(null);
  const [resizedSize, setResizedSize] = useState(null);
  const [uploading, setUploading] = useState(false); // For managing spinner during upload
  const [uploadProgress, setUploadProgress] = useState(0);

  const [imageUri, setImageUri] = useState(null);
  const [processing, setProcessing] = useState(false);
 





  

  const handleCancelEdit = () => {
    setUserName(originalUserName);  // Revert to the original user name
    setEmail(originalEmail);        // Revert to the original email
    setSelectedImageUri("");        // Reset the selected image URI
    setEditing(false);              // Exit editing mode
  };

  // Function to get initials
  const getInitials = (name) => {
    if (!name) return "";

    const nameParts = name.split(" ");
    let initials = nameParts[0].charAt(0).toUpperCase(); // First letter of the first name

    if (nameParts.length > 1) {
      initials += nameParts[1].charAt(0).toUpperCase(); // First letter of the last name (if available)
    }

    return initials;
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", fetchUserData);
    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    const headerRightComponent = () => (
      <View style={{ alignItems: "center", marginRight: 28 }}>
        <Avatar
          rounded
          size="medium"
          source={{ uri: avatarUriURL || "https://i.pravatar.cc/300" }}
          activeOpacity={0.7}
        />
        <Text style={{ color: "white", fontSize: 14, marginTop: 0 }}>
          {userName}
        </Text>
      </View>
    );

    navigation.setOptions({
      headerRight: headerRightComponent,
    });
  }, [userName, avatarUriURL, navigation]);

  const fetchUserData = useCallback(async () => {
    const firestoreInstance = getFirestore();

    if (globalUserId) {
      const userDocRef = doc(firestoreInstance, "users", globalUserId);
      try {
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setUserName(userData.name);
          setEmail(userData.email);
          setAvatarUriURL(userData.avatarUri);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  }, [globalUserId]);
  


  const uploadToFirebase = async (uri) => {
    console.log('Starting image upload to Firebase Storage');
  
    try {
      setUploading(true); // Start the spinner during upload
  
      // Convert the image URI to a Blob
      const response = await fetch(uri);
      const blob = await response.blob();
  
      // Create a unique file name based on timestamp
      const timestamp = Date.now();
      const fileName = `profile-picture_${timestamp}.jpg`;
  
      // Create a reference to Firebase Storage at 'test/${fileName}'
      const storage = getStorage();
      //const storageRef = ref(storage, `test/${fileName}`);
      const storageRef = ref(storage, `userProfile/${auth.currentUser.uid}/profile-picture`);

  
      // Start the upload process
      const uploadTask = uploadBytesResumable(storageRef, blob);
  
      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
            setUploadProgress(progress);
          },
          (error) => {
            console.error('Upload failed:', error);
            Alert.alert('Upload Error', 'Failed to upload image. Please try again.');
            setUploadProgress(0);
            setUploading(false); // Stop the spinner on error
            reject(error);
          },
          async () => {
            // Handle successful uploads
            if (uploadTask.snapshot && uploadTask.snapshot.ref) {
              const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
              console.log('Image uploaded successfully, download URL:', downloadUrl);
              Alert.alert('Upload Success', 'Image uploaded successfully!');
              setUploadProgress(0); // Reset progress
              setUploading(false); // Stop the spinner on success
              resolve(downloadUrl);
            } else {
              console.error('Upload snapshot or ref is undefined');
              Alert.alert('Upload Error', 'Failed to retrieve upload information.');
              setUploading(false); // Stop the spinner if ref is undefined
              reject(new Error('Upload snapshot or ref is undefined'));
            }
          }
        );
      });
    } catch (error) {
      console.error('Error uploading image:', error);
      Alert.alert('Upload Error', 'An error occurred while uploading the image.');
      setUploading(false); // Stop the spinner on catch
      throw error; // Re-throw the error for further handling if needed
    }
  };
  


  // Image cropping and resizing logic
  
  const cropImageTo1MB = async (uri) => {
    try {
      if (!uri) {
        console.error('cropImageTo1MB: uri is undefined');
        return null;
      }
  
      // Skip logic entirely for web
      if (Platform.OS === 'web') {
        console.log('Skipping image manipulation for web platform');
        return uri; // Return the original URI for web
      }
  
      // iOS-specific logic to handle "ph://" URIs
      if (Platform.OS === 'ios' && uri.startsWith('ph://')) {
        const fileName = uri.split('/').pop();
        const newPath = FileSystem.cacheDirectory + fileName;
        try {
          await FileSystem.copyAsync({
            from: uri,
            to: newPath,
          });
          uri = newPath;
          console.log('Copied Image URI:', uri);
        } catch (error) {
          console.error('Error copying image:', error);
          return null;
        }
      }
  
      // Original image resizing/compression logic
      let compressQuality = 0.9; // Start with 90% quality
      let resizeFactor = 0.9; // Start with 90% of original dimensions
      let manipulatedImage = uri;
  
      while (true) {
        const { width, height } = await getImageDimensions(manipulatedImage);
  
        // Resize the image
        const resized = await ImageManipulator.manipulateAsync(
          manipulatedImage,
          [{ resize: { width: width * resizeFactor, height: height * resizeFactor } }],
          { compress: compressQuality, format: ImageManipulator.SaveFormat.JPEG }
        );
  
        manipulatedImage = resized.uri;
  
        // Check file size after resizing
        const fileInfo = await FileSystem.getInfoAsync(manipulatedImage);
        const fileSizeInMB = fileInfo.size / (1024 * 1024);
  
        if (fileSizeInMB <= 1 || compressQuality < 0.3) {
          break; // Stop resizing if file size is under 1MB or quality is too low
        }
  
        // Reduce compression quality and resize factor for next iteration
        compressQuality -= 0.1;
        resizeFactor -= 0.1;
      }
  
      console.log('Final manipulated image URI:', manipulatedImage);
      return manipulatedImage;
    } catch (error) {
      console.error('Error manipulating image:', error);
      return null;
    }
  };
  

  // Helper function to get image dimensions
  const getImageDimensions = async (uri) => {
    return new Promise((resolve, reject) => {
      Image.getSize(
        uri,
        (width, height) => resolve({ width, height }),
        (error) => {
          console.error('Error getting image dimensions:', error);
          reject(error);
        }
      );
    });
  };

  
const handleSaveProfileImage = async () => {
  console.log('Upload Profile Image button pressed');

  // Request media library permissions if not already granted
  const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();
  if (!permissionResult.granted) {
    console.log('Permission to access photo library denied');
    Alert.alert("Permission Denied", "Permission to access the photo library is required!");
    return;
  }

  console.log('Permission granted to access photo library');

  try {
    // Launch image picker
    let pickerResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 1,
    });

    if (!pickerResult.canceled && pickerResult.assets && pickerResult.assets.length > 0) {
      const selectedAsset = pickerResult.assets[0];
      if (selectedAsset.uri) {
        console.log('Image selected by the user:', selectedAsset.uri);

        // Crop and resize the image to be under 1MB
        const croppedUri = await cropImageTo1MB(selectedAsset.uri);
        if (croppedUri) {
          console.log(`Cropped and resized image URI: ${croppedUri}`);

          // Set the processed image URI to state
          setSelectedImageUri(croppedUri);

          // Optionally, you can upload immediately or wait until the user saves
          // For this example, we'll wait until the user presses 'Save'
        }
      } else {
        console.log('Selected asset does not have a URI.');
        Alert.alert('Selection Error', 'Failed to retrieve the image URI.');
      }
    } else {
      console.log('Image selection was canceled by the user or no assets returned.');
    }
  } catch (error) {
    console.error('Error during image selection:', error);
    Alert.alert('Error', 'An error occurred while selecting the image.');
  }
};


const handleSaveProfile = async () => {
  if (editing) {
    setIsLoading(true);
    let updatedData = {};

    try {
      // 1. Handle Avatar/Image Update
      if (selectedImageUri) {
        const downloadUrl = await uploadToFirebase(selectedImageUri);
        setAvatarUriURL(downloadUrl);

        updatedData.avatarUri = downloadUrl; // Prepare to update global state
      }

      // 2. Handle Name and Email Update
      const hasNameChanged = userName !== originalUserName;
      const hasEmailChanged = email !== originalEmail;

      if (hasNameChanged || hasEmailChanged) {
        const updatedFields = {};

        if (hasNameChanged) {
          updatedFields.name = userName;
          updatedData.name = userName; // Prepare to update global state
        }

        if (hasEmailChanged) {
          updatedFields.email = email;
          updatedData.email = email; // Prepare to update global state
        }

        // Update Firestore 'users' collection with only changed fields
        const userRef = doc(database, "users", auth.currentUser.uid);
        await updateDoc(userRef, updatedFields);

        // Update related 'MainGame' documents
        await handleSaveUsername(updatedFields);
      }

      // 3. Update global state with the new avatar and/or name/email
    setUser((prev) => ({
  ...prev,
  ...updatedData,
}));

      setEditing(false);
    } catch (error) {
      console.error('Error saving profile:', error);
      Alert.alert('Save Error', 'An error occurred while saving your profile.');
    } finally {
      setIsLoading(false);
    }
  }
};








const handleSaveUsername = async (updatedFields) => { // Accept updatedFields as a parameter
  const userId = auth.currentUser.uid;
  const userRef = doc(database, "users", userId);

  try {
    // No need to update 'users' collection here as it's already handled in handleSaveProfile

    // Update related 'MainGame' documents only if relevant fields have changed
    const mainGameRef = collection(database, "MainGame");
    const mainGameSnapshot = await getDocs(mainGameRef);

    for (const mainGameDoc of mainGameSnapshot.docs) {
      let gameData = mainGameDoc.data();

      // Check if the user is a member of the game
      const memberIndex = gameData.memberOfGame.findIndex((member) => member.uid === userId);
      if (memberIndex !== -1) {
        // Update only the changed fields in memberOfGame
        const updatedMember = {
          ...gameData.memberOfGame[memberIndex],
          ...updatedFields,
        };

        // Replace the specific member with the updated member
        const updatedMemberOfGame = [...gameData.memberOfGame];
        updatedMemberOfGame[memberIndex] = updatedMember;

        // Update Firestore 'MainGame' document
        await updateDoc(doc(database, "MainGame", mainGameDoc.id), {
          memberOfGame: updatedMemberOfGame,
        });

        console.log(`Updated memberOfGame in MainGame document: ${mainGameDoc.id}`);
      }

      // Additionally, iterate over 'TheGame' subcollections to update player details
      const theGameRef = collection(mainGameDoc.ref, "TheGame");
      const theGameSnapshot = await getDocs(theGameRef);

      for (const theGameDoc of theGameSnapshot.docs) {
        // Update only if player exists
        const playerDocRef = doc(theGameDoc.ref, "Players", userId);
        const playerDocSnapshot = await getDoc(playerDocRef);

        if (playerDocSnapshot.exists()) {
          // Update only the changed fields in 'Players' subcollection
          await updateDoc(playerDocRef, updatedFields);
          console.log(`Updated player ${userId} in ${theGameDoc.id} within MainGame ${mainGameDoc.id}`);
        }
      }
    }
  } catch (error) {
    console.error("Error updating username:", error);
  }
};

const handleEditProfile = async () => {
  if (editing) {
    setIsLoading(true);

    try {
      // Update profile by saving changed fields
      await handleSaveProfile();
    } catch (error) {
      console.error("Error updating user profile:", error);
      Alert.alert("Update Error", "An error occurred while updating your profile.");
    } finally {
      setIsLoading(false);
    }
  } else {
    // Enter editing mode
    setOriginalUserName(userName);
    setOriginalEmail(email);
    setEditing(true);
  }
};

  


  return (
  <ScrollView contentContainerStyle={styles.container}>
    <Text style={styles.title}>My Profile</Text>
    {isLoading ? (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size={50} color="#0000ff" />
        <Text style={styles.loadingText}>
          Updating, this may take a minute...
        </Text>
      </View>
    ) : (
      <>
      {editing ? (
  <>
    <Text style={styles.label}>User Name:</Text>
    <TextInput
      value={userName}
      onChangeText={setUserName}
      style={styles.input}
      placeholder="User Name"
    />
    <TouchableOpacity
      onPress={handleSaveProfileImage} // Ensure this function is active
      style={styles.uploadButton}
    >
      <Text style={styles.buttonText}>Upload Profile Image</Text>
    </TouchableOpacity>
    {selectedImageUri || avatarUriURL ? (
      <Avatar
        rounded
        size={120}
        source={{ uri: selectedImageUri || avatarUriURL }}
        activeOpacity={0.7}
        containerStyle={styles.avatar}
      />
    ) : (
      <View style={styles.initialsAvatar}>
        <Text style={styles.initialsText}>{getInitials(userName)}</Text>
      </View>
    )}
    <View style={styles.buttonGroup}>
      <Pressable
        onPress={handleSaveProfile}
        style={({ pressed }) => [
          styles.button,
          styles.saveButton,
          { opacity: pressed ? 0.7 : 1 },
        ]}
      >
        <Text style={styles.buttonText}>Save</Text>
      </Pressable>
      <Pressable
        onPress={handleCancelEdit}
        style={({ pressed }) => [
          styles.button,
          styles.cancelButton,
          { opacity: pressed ? 0.7 : 1 },
        ]}
      >
        <Text style={styles.buttonText}>Cancel</Text>
      </Pressable>
    </View>
  </>
        ) : (
          <>
            <Text style={styles.text}>{userName}</Text>
            <Text style={styles.text}>{email}</Text>
            {avatarUriURL ? (
              <Avatar
                rounded
              //  size="xlarge"
                size={120} 
                source={{ uri: avatarUriURL }}
                activeOpacity={0.7}
                containerStyle={styles.avatar}
              />
            ) : (
              <View style={styles.initialsAvatar}>
                <Text style={styles.initialsText}>{getInitials(userName)}</Text>
              </View>
            )}
            <Pressable
              onPress={handleEditProfile}
              style={({ pressed }) => [
                styles.button,
                { backgroundColor: "#4361ee", opacity: pressed ? 0.7 : 1 },
              ]}
            >
              <Text style={styles.buttonText}>Edit Profile</Text>
            </Pressable>

            <Pressable
              onPress={() => navigation.navigate('AccountSettings')}
              style={({ pressed }) => [
                styles.button,
                { backgroundColor: "#4361ee", opacity: pressed ? 0.5 : 1 },
              ]}
            >
              <Text style={styles.buttonText}>Manage Account</Text>
            </Pressable>

            <Pressable
              onPress={() => navigation.navigate('NotificationSettings')}
              style={({ pressed }) => [
                styles.button,
                { backgroundColor: "#4361ee", opacity: pressed ? 0.5 : 1 },
              ]}
            >
              <Text style={styles.buttonText}>NotificationSettings</Text>
            </Pressable>

            <Pressable
              onPress={() =>
                Linking.openURL(
                  "https://www.privacypolicies.com/live/5bd23615-8735-4c49-9ce2-5864543b187c"
                )
              }
              style={({ pressed }) => [
                styles.button,
                { backgroundColor: "#4361ee", opacity: pressed ? 0.5 : 1 },
              ]}
            >
              <Text style={styles.buttonText}>Privacy Policy</Text>
            </Pressable>
          </>
        )}
      </>
    )}
  </ScrollView>
);
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f8f9fa",
    padding: 20,
  },
  title: {
    fontSize: 28,
    marginBottom: 20,
    fontWeight: "bold",
    color: "#343a40",
  },
  label: {
    fontSize: 18,
    color: "#495057",
    marginBottom: 8,
  },
  input: {
    borderColor: "#ced4da",
    borderWidth: 1,
    borderRadius: 8,
    padding: 12,
    width: "100%",
    marginBottom: 15,
    fontSize: 16,
  },
  button: {
    marginTop: 12,
    paddingVertical: 12,
    paddingHorizontal: 25,
    borderRadius: 8,
    shadowOpacity: 0.3,
    shadowRadius: 4,
    shadowColor: "#000000",
    shadowOffset: { height: 2, width: 0 },
    elevation: 4,
  },
  buttonText: {
    fontSize: 16,
    color: "#ffffff",
    fontWeight: "600",
    textAlign: "center",
  },
  avatar: {
    marginBottom: 25,
    borderWidth: 3,
    borderColor: "#ced4da",
  },
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  loadingText: {
    marginTop: 10,
    fontSize: 16,
    color: "#495057",
  },
  buttonGroup: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 10,
  },
  saveButton: {
    backgroundColor: "#28a745",
    flex: 1,
    marginRight: 10,
  },
  cancelButton: {
    backgroundColor: "#dc3545",
    flex: 1,
  },
  uploadButton: {
    backgroundColor: "#007bff",
    marginBottom: 20,
  },
  text: {
    fontSize: 18,
    color: "#495057",
    marginBottom: 12,
  },
  initialsAvatar: {
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: "#4361ee",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 25,
  },
  initialsText: {
    fontSize: 40,
    color: "#ffffff",
    fontWeight: "bold",
  },

});

export default Profile;
