// plerys.js 

import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  SafeAreaView,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  ScrollView,
  Platform,
  Pressable,
  ActivityIndicator 
} from "react-native";

import { FontAwesome } from "@expo/vector-icons";

import RadioButton from "./RadioButton";

import { useUser } from "../navigation/UserContext"; // Adjust the path as needed

import { Avatar, Button } from "react-native-elements";
import Ionicons from "@expo/vector-icons/Ionicons";

import { MaterialIcons } from "@expo/vector-icons";

import { getAuth, signOut } from "firebase/auth";
const auth = getAuth();

import { database } from "../componenets/firebase";
import {
  collection,
  query,
  getDocs,
  getDoc,
  setDoc,
  onSnapshot,
  where,
  orderBy,
  doc,
  updateDoc,
  addDoc,
  Timestamp,
  writeBatch,
  getFirestore
} from "firebase/firestore";

const db = getFirestore();


import Chat from "./Chat"; // Adjust the path if Chat.js is in a different directory

import useAvatarUri from "./Avatar";
import moment from "moment";  // Import moment here

import { sendNotificationToPlayer } from './notificationUtils'; // Import from notificationUtils.js



 

const Players = ({ navigation, route }) => {
  const [mainGame, setMainGame] = useState(route.params.mainGameId);
  const [TheGameId, setTheGameId] = useState(route.params.TheGameId);
  const [gameDate, setGameDate] = useState(route.params.GameDate);
  const [time, setTime] = useState(route.params.time);
  const [location, setLocation] = useState(route.params.location);
  const [isLoading, setIsLoading] = useState(false);


  const GameName = route.params.GameName; // Adjust based on actual parameter names

  const [GameIndex, setGameIndex] = useState(route.params.GameIndex);
  const [players, setPlayers] = useState([]);
  const [option, setOption] = useState(null);

  const { user } = useUser(); // Using the useUser hook to access the user context
  const [showChat, setShowChat] = useState(false); // New state variable to toggle chat view

  const currentUserEmail = getAuth().currentUser?.email;
  const [currentUserName, setCurrentUserName] = useState(null); // State to store current user's name
  const [playerTokenStatus, setPlayerTokenStatus] = useState({}); // Store each player's token status


 

  // Function to toggle the chat view
  const toggleChatView = () => {
    setShowChat(!showChat);
  };

  const fetchAvatarUri = useAvatarUri();

  const [isAdmin, setIsAdmin] = useState(false);
  const [gameMode, setGameMode] = useState(null);

  const formattedDate = moment(gameDate).format("D.MMM YYYY");

  const [goingCount, setGoingCount] = useState(0);
  const [notGoingCount, setNotGoingCount] = useState(0);
  const [waitingCount, setWaitingCount] = useState(0);


   // Function to check if players have tokens
   const checkPlayerTokens = async () => {
    const updatedTokenStatus = {};

    for (const player of players.flatMap(p => p.memberOfGame)) {
      const userDocRef = doc(db, 'users', player.uid);
      const userSnapshot = await getDoc(userDocRef);

      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        updatedTokenStatus[player.uid] = !!userData.expoPushToken && userData.expoPushToken !== 'No Token';
      } else {
        updatedTokenStatus[player.uid] = false; // No token if user doc doesn't exist
      }
    }

    setPlayerTokenStatus(updatedTokenStatus);
  };

  // Run token check when players data changes
  useEffect(() => {
    if (players.length > 0) {
      checkPlayerTokens();
    }
  }, [players]);


const checkAndSendNotification = async (player) => {
  try {
    // Fetch the user document from the users collection
    const userDocRef = doc(db, 'users', player.uid);
    const userSnapshot = await getDoc(userDocRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      const expoPushToken = userData.expoPushToken;

      if (expoPushToken && expoPushToken !== 'No Token') {
        // Log and send the notification
        //console.log("Sending reminder to User ID:", player.uid, "with token:", expoPushToken);
        await sendNotificationToPlayer(expoPushToken, player.name, currentUserName);
      } else {
        //console.log(`User ID ${player.uid} does not have a valid Expo Push Token.`);
        Alert.alert('Notification Failed', `${player.name} does not have a valid Expo Push Token.`);
      }
    } else {
      //console.log(`User document for ID ${player.uid} not found.`);
    }
  } catch (error) {
    //console.error("Error checking token and sending notification:", error);
  }
};



  const playersCollection = collection(
    database,
    "MainGame",
    mainGame,
    "TheGame",
    TheGameId,
    "Players"
  );

  // Define query variables outside of the useEffect
  const goingQuery = query(playersCollection, where("value", "==", "Going"));
  const notGoingQuery = query(
    playersCollection,
    where("value", "==", "Not going")
  );
  const waitingQuery = query(
    playersCollection,
    where("value", "==", "Waiting")
  );

  useEffect(() => {
    const fetchIsAdminStatus = async () => {
      try {
        const mainGameDoc = doc(database, "MainGame", mainGame);
        const mainGameSnapshot = await getDoc(mainGameDoc);
        const { memberOfGame, mode } = mainGameSnapshot.data(); // Get the mode field

        // Update the gameMode state
        setGameMode(mode); // Set the gameMode state to whatever is in Firestore

        const currentUser = auth.currentUser;

        if (currentUser) {
          const currentUserId = currentUser.uid;

          const currentUserData = memberOfGame.find(
            (member) => member.uid === currentUserId
          );

          if (currentUserData) {
            const adminStatus = currentUserData.isAdmin;
            setIsAdmin(adminStatus === true);
          }
        }
      } catch (error) {
        console.error("Error in fetchIsAdminStatus:", error);
      }
    };

    fetchIsAdminStatus();
  }, []);

  const selectedData = [
    { id: 1, value: "Going", icon: "check", bg: "white", radiobg: "green" },
    { id: 2, value: "Not going", icon: "remove", bg: "white", radiobg: "red" },
    { id: 3, value: "Waiting", icon: "question", bg: "black", radiobg: "grey" },
  ];

  useEffect(() => {
    const colRef = collection(database, "MainGame");
    const q = query(colRef, where("MainGameId", "==", mainGame));

    const unsubscribe = onSnapshot(
      q,
      async (querySnapshot) => {
        const fetchedData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const gameData = doc.data();
            const memberOfGame = await Promise.all(
              gameData.memberOfGame.map(async (player) => {
                const avatarUri = await fetchAvatarUri(player.uid);
                return { ...player, avatarUri };
              })
            );

            // Sort the memberOfGame array by player name
            const sortedMembers = [...memberOfGame].sort((a, b) =>
              a.name.localeCompare(b.name)
            );

            return { ...gameData, memberOfGame: sortedMembers, id: doc.id };
          })
        );

        setPlayers(fetchedData);
      },
      (error) => {
        console.error("Firebase query error:", error);
      }
    );

    // Unsubscribe from the snapshot listener when the component is unmounted.
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const goingSnapshot = await getDocs(goingQuery);
        setGoingCount(goingSnapshot.size);
  
        const notGoingSnapshot = await getDocs(notGoingQuery);
        setNotGoingCount(notGoingSnapshot.size);
  
        const waitingSnapshot = await getDocs(waitingQuery);
        setWaitingCount(waitingSnapshot.size);
      } catch (error) {
        console.error("Error fetching counts:", error);
      }
    };
  
    const unsubscribeGoing = onSnapshot(goingQuery, () => {
      fetchCounts();
    });
  
    const unsubscribeNotGoing = onSnapshot(notGoingQuery, () => {
      fetchCounts();
    });
  
    const unsubscribeWaiting = onSnapshot(waitingQuery, () => {
      fetchCounts();
    });
  
    return () => {
      unsubscribeGoing();
      unsubscribeNotGoing();
      unsubscribeWaiting();
    };
  }, [mainGame, TheGameId, playersCollection]);
  
 
   // Render function for the Pressable button
   const renderButton = (title, iconName, onPress, isPressed) => (
    <Pressable
      onPress={onPress}
      style={({ pressed }) => [
        styles.button,
        pressed ? styles.pressedButton : styles.defaultButton,
        isPressed && styles.pressedButton, // Apply gray color if the corresponding view is active
      ]}
    >
      {({ pressed }) => (
        <View style={styles.buttonContent}>
          <MaterialIcons name={iconName} size={20} color="white" />
          <Text style={styles.buttonText}>
            {pressed ? title : title}
          </Text>
        </View>
      )}
    </Pressable>
  );


  const calculateAndStoreTotalPointsBefore = async (mainGame) => {
    try {
        console.log("Started calculateAndStoreTotalPointsBefore");

        const gamesRef = collection(database, "MainGame", mainGame, "TheGame");
        const gamesSnapshot = await getDocs(gamesRef);

        const gameDates = gamesSnapshot.docs
            .map((gameDoc) => ({
                gameId: gameDoc.id,
                gameDate: new Date(gameDoc.data().date),
            }))
            .sort((a, b) => a.gameDate - b.gameDate);

        console.log("Fetched game dates:", gameDates);

        const playerCumulativeTotals = {};

        for (let i = 0; i < gameDates.length; i++) {
            const { gameId: currentGameId } = gameDates[i];
            const isFirstGame = i === 0;

            console.log(`Processing Game ID: ${currentGameId}, isFirstGame: ${isFirstGame}`);

            const playersRef = collection(database, "MainGame", mainGame, "TheGame", currentGameId, "Players");
            const playersSnapshot = await getDocs(playersRef);

            const batch = writeBatch(database);

            for (const playerDoc of playersSnapshot.docs) {
                const playerData = playerDoc.data();
                const playerId = playerData.userId;

                if (!playerId) {
                    console.error(`Player ID is undefined for player: ${JSON.stringify(playerData)}`);
                    continue;
                }

                 // Initialize cumulative totals if this is the first game for the player
                if (!playerCumulativeTotals[playerId]) {
                  playerCumulativeTotals[playerId] = {
                    totalPointsBefore: 0,
                    totalGDTest: 0,
                    totalGFbefore: 0, // Initialize totalGFbefore
                  };
                }

                const cumulativeData = playerCumulativeTotals[playerId];

               // Set cumulative totals to 0 for the first game, otherwise use accumulated values
               const totalPointsBefore = isFirstGame ? 0 : cumulativeData.totalPointsBefore;
               const totalGDTest = isFirstGame ? 0 : cumulativeData.totalGDTest;
               const totalGFbefore = isFirstGame ? 0 : cumulativeData.totalGFbefore; // Add this line
       

                console.log(`Initial totals for Player ID ${playerId} before current game:`);
                console.log(`  totalPointsBefore: ${totalPointsBefore}`);
                console.log(`  totalGDTest: ${totalGDTest}`);

                // Log the current game's `totalPoints` and `gd` to be added
                const currentGamePoints = playerData.totalPoints || 0;
                const currentGameGD = playerData.gd || 0;

                console.log(`Current game points for Player ID ${playerId}:`);
                console.log(`  totalPoints from this game: ${currentGamePoints}`);
                console.log(`  GD from this game: ${currentGameGD}`);

                // Check if a document with this userId already exists in Players collection
                const existingPlayerDoc = playersSnapshot.docs.find(
                    (doc) => doc.data().userId === playerId
                );

                if (existingPlayerDoc) {
                    // Update existing document
                    const existingPlayerRef = doc(playersRef, existingPlayerDoc.id);
                    batch.update(existingPlayerRef, {
                        totalPointsBefore,
                        totalGDbeforeTest: totalGDTest,
                        totalGFbefore, // Include totalGFbefore

                    });
                    console.log(`Updating existing document for userId ${playerId}:`, {
                        totalPointsBefore,
                        totalGDbeforeTest: totalGDTest,
                        totalGFbefore,
                    });
                } else {
                    // Create new document
                    const playerRef = doc(playersRef);
                    batch.set(playerRef, {
                        ...playerData,
                        totalPointsBefore,
                        totalGDbeforeTest: totalGDTest,
                        totalGFbefore,
                    }, { merge: true });
                    console.log(`Creating new document for player ID ${playerId}:`, {
                        totalPointsBefore,
                        totalGDbeforeTest: totalGDTest,
                        totalGFbefore,

                    });
                }

                // Accumulate totals for the next game
                cumulativeData.totalPointsBefore += playerData.totalPoints || 0;
                cumulativeData.totalGDTest += playerData.gd || 0;
                cumulativeData.totalGFbefore += playerData.gf || 0; // Accumulate totalGFbefore


                console.log(`Updated cumulative totals for Player ID ${playerId} after current game:`);
                console.log(`  totalPointsBefore: ${cumulativeData.totalPointsBefore}`);
                console.log(`  totalGDTest: ${cumulativeData.totalGDTest}`);
            }

            await batch.commit();
            console.log(`Batch committed for Game ID: ${currentGameId}`);
        }

        console.log("Final cumulative totals for each player:", playerCumulativeTotals);

    } catch (error) {
        console.error("Error calculating and saving totalPointsBefore and totalGDTest:", error);
    }
};












const [statusChanged, setStatusChanged] = useState({}); // Track true/false for each player


const handlePlayerStatusChange = async (playerId, ButtonId) => {
  try {
    const playerRef = doc(database, "MainGame", mainGame, "TheGame", TheGameId, "Players", playerId);

    // Fetch the player's current data
    const playerDoc = await getDoc(playerRef);
    if (!playerDoc.exists()) {
      console.error(`Player document with ID ${playerId} does not exist.`);
      setStatusChanged(prev => ({ ...prev, [playerId]: false }));
      return;
    }

    const playerData = playerDoc.data();
    //console.log("Current player data:", playerData);

    // Handle logic based on ButtonId (1, 2, or 3)
    if (ButtonId === 2 || ButtonId === 3) {
      //console.log(`Resetting totalPoints and other fields for player ${playerId} due to ButtonId: ${ButtonId}`);

      // Reset specific fields when ButtonId is 2 (Not going) or 3 (Waiting)
      await updateDoc(playerRef, {
        totalPoints: 0,
        gd: 0,
        ga: 0,
        gf: 0,
        drawPoints: 0,
        attendancePoints: 0,
        totalPointsBefore: 0,
        totalPointsBefore: 0,
        totalGDbeforeTest: 0,
      });

      setStatusChanged(prev => ({ ...prev, [playerId]: true }));
    } else if (ButtonId === 1) {
      //console.log(`ButtonId 1 selected for player ${playerId}. No data reset triggered.`);
      setStatusChanged(prev => ({ ...prev, [playerId]: false }));
    }
  } catch (error) {
    console.error("Error updating player status:", error);
  }
};


useEffect(() => {
  const fetchCurrentUserName = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (currentUser) {
      const userDocRef = doc(database, 'users', currentUser.uid);
      const userSnapshot = await getDoc(userDocRef);

      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        setCurrentUserName(userData.name || 'Unknown');
      } else {
        //console.log("User document not found.");
      }
    }
  };

  fetchCurrentUserName();
}, []);
  
  
  
  
  
  

  
  
//const currentUserEmail = getAuth().currentUser?.email;

  

  // Inside Players component
const renderPlayerItem = ({ item }) => (
  <View style={styles.playerItem}>
    <ScrollView showsHorizontalScrollIndicator={false}>
      {item.memberOfGame.map((player, index) => (
        <View key={index} style={styles.playerRow}>
          <View style={styles.playerInfo}>
            <Avatar
              size="small"
              rounded
              source={{ uri: player.avatarUri }}
              title={player.name.slice(0, 2).toUpperCase()}
              overlayContainerStyle={{ backgroundColor: "#bcbec1" }}
            />
            <Text style={styles.playerName}>{player.name}</Text>
          </View>

          <RadioButton
  data={selectedData}
  mainGame={mainGame}
  TheGameId={TheGameId}
  playerId={player.uid}
  name={player.name}
  email={player.email}
  GameDate={gameDate}
  GameIndex={GameIndex}
  onSelect={(value) => handlePlayerStatusChange(player.uid, value)}
  checkAndSendNotification={() => checkAndSendNotification(player)}
  playerTokenStatus={playerTokenStatus[player.uid]} // Pass specific player's token status
/>

        </View>
      ))}
    </ScrollView>
  </View>
);


  return (
 <SafeAreaView style={{ flex: 1 }}>
     <View style={styles.titleSection}>
      <Text style={styles.titleText}>Game: {GameName}</Text>
       <View style={styles.detailContainer}>
        <Text style={styles.detailText}>Date: {formattedDate}</Text>
        <Text style={styles.detailText}>Time: {time}</Text>
      </View>
      <View style={styles.detailContainer}>
        <Text style={styles.detailText}>Location: {location}</Text>        
      </View>
     
    </View>

 

      <TouchableOpacity
  style={styles.continueButton}
  onPress={async () => {
    setIsLoading(true); // Start loading spinner
    try {
      await calculateAndStoreTotalPointsBefore(mainGame);
      console.log('Navigating to Results after storing data');
      navigation.navigate("Results", {
        TheGameId,
        mainGame,
        gameDate,
      });
    } catch (error) {
      console.error("Error calculating totalPointsBefore and navigating:", error);
    } finally {
      setIsLoading(false); // Stop loading spinner
    }
  }}
>
  {isLoading ? (
    <ActivityIndicator size="small" color="#fff" /> // Display spinner
  ) : (
    <Text style={styles.continueButtonText}>Continue to TEAMS</Text> // Display button text when not loading
  )}
</TouchableOpacity>


 
  



   
      <View style={styles.buttonContainer}>
      {renderButton("Squad", "people", () => setShowChat(false), !showChat)}
      {renderButton("Chat", "chat", () => setShowChat(true), showChat)}
      
    </View>


    
    <View style={styles.statusTextContainer}>
        <Text style={styles.statusText}>Going: {goingCount}</Text>
        <Text style={styles.statusText}>Not Going: {notGoingCount}</Text>
        <Text style={styles.statusText}>Waiting: {waitingCount}</Text>
        
      </View>


      {!showChat ? (
        // FlatList and Player Items
        
        
 

        
      <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
        <FlatList
          data={players}
          renderItem={renderPlayerItem}
          keyExtractor={(item) => item.id}
          //contentContainerStyle={styles.flatListContent} // Added this line

        />
      </ScrollView>








      ) : (
        <Chat
          mainGameId={mainGame}
          TheGameId={TheGameId}
          senderName={user?.name || "unknown"}
        />
      )}


      

 
 
    
   
   
    </SafeAreaView>
  );
};

export default Players;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    position: "relative",
  },
  continueButton: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 5,
    borderRadius: 25,
    backgroundColor: "#4C7BEE",
    borderWidth: 2,
    borderColor: "#2E7D32",
    textAlign: "center",
  },
  continueButtonText: {
    color: "white",
    fontSize: 15,
    fontWeight: "500",
    alignSelf: "center",
  },
  playerItem: {
    padding: 10,
    borderRadius: 5,
    marginVertical: 5,
    marginHorizontal: 10,
  },
  playerName: {
    marginLeft: 10,
    color: "#1E0FCA",
    fontSize: 15,
    width: 100,
    paddingLeft: 2,
  },
  playerRow: {
    flexDirection: "row",
    alignItems: "center",
    padding: 2,
    borderBottomWidth: 1,
    borderColor: "#d3d3d3",
  },
  playerInfo: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
  },
  radioButtonWrapper: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  titleSection: {
    backgroundColor: "#F7F9FC",
    paddingVertical: 20,
    paddingHorizontal: 15,
    borderRadius: 12,
    marginHorizontal: 15,
    marginTop: 15,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 5,
    alignItems: 'center', // Ensure content is centered
  },
  titleText: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#4361ee",
    textAlign: 'center', // Center text horizontally
  },
  detailContainer: {
    flexDirection: 'row', // Detail label and text in a row
    justifyContent: 'center', // Center content horizontally
    marginVertical: 4, // Vertical spacing for readability
  },
  detailText: {
    fontSize: 14,
    color: "#64748B",
    fontWeight: "normal",
    marginLeft: 5, // Optional: Add if you need space between label and value
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    padding: 10,
  },
  statusTextContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    backgroundColor: "#F5F5F5", // Background color for the text section
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#DADADA", // Border color for the text section
  },
  statusText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#333", // Text color for status labels
  },
    // Dynamic styles for the pressed state
    pressedButton: {
    backgroundColor: "#6c757d", // Bootstrap's gray color for demonstration
  },
  // Style for the non-pressed (default) state
  defaultButton: {
    backgroundColor: "#007bff", // Bootstrap's blue color for demonstration
  },
  buttonContent: {
    flexDirection: 'row',  // Places the icon and text in a row (next to each other)
    alignItems: 'center',  // Vertically centers the icon and text
  },

  buttonText: {
    marginLeft: 8,  // Adds space between the icon and the text
    color: "#FFFFFF",
    fontSize: 20,
  },



});
