import React, { useState, useCallback } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  SafeAreaView,
  TextInput,
  ActivityIndicator,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  Alert,
  ImageBackground,
} from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { Button } from "react-native-elements";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getAndStorePushToken } from "./getToken"; // Adjust the path if necessary
import { joinDemoGame } from "../Demo"; // Adjust the path as needed


import {
  setDoc,
  doc,
  getFirestore,
  updateDoc,
  getDocs,
  collection,
  getDoc,
  serverTimestamp,  // Import the serverTimestamp

} from "firebase/firestore";

import { useFocusEffect } from "@react-navigation/native";

const auth = getAuth();
const firestore = getFirestore();

const SignUpScreen = ({ navigation }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [inputFocus, setInputFocus] = useState({
    email: false,
    firstName: false,
    password: false,
    confirmPassword: false,
  });

  const handleFocus = (field) => {
    setInputFocus((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => {
    setInputFocus((prev) => ({ ...prev, [field]: false }));
  };

  useFocusEffect(
    useCallback(() => {
      setEmail("");
      setFirstName("");
      setPassword("");
      setConfirmPassword("");
      setValidationMessage("");
    }, [])
  );

  const validateForm = () => {
    if (!email || !firstName || !password || !confirmPassword) {
      setValidationMessage("All fields are required.");
      return false;
    }
    if (password !== confirmPassword) {
      setValidationMessage("Passwords do not match.");
      return false;
    }
    if (password.length < 8) {
      setValidationMessage("Password must be at least 8 characters long.");
      return false;
    }
    // Add more password complexity checks here if needed
    return true;
  };

  const createAccount = useCallback(async () => {
    if (!validateForm()) return;
    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log("Account creation successful", userCredential.user.uid);
      await setDoc(doc(firestore, "users", userCredential.user.uid), {
        email,
        name: firstName,
        userId: userCredential.user.uid,
        createdAt: serverTimestamp(),
      });

      
        // ADDED: Join the Demo game using the joinDemoGame function from Demo.js
        await joinDemoGame(userCredential.user.uid, firstName, email);

  
      // Call updateUserDataAfterSignUp to complete any additional setup
      await updateUserDataAfterSignUp(email, [{ name: firstName }]);
  
      // Store the user's Expo push token
      await getAndStorePushToken();

  
      navigation.navigate("Home"); // Navigate to the home screen
    } catch (error) {
      let message;
      switch (error.code) {
        case "auth/email-already-in-use":
          message = "This email is already in use.";
          break;
        case "auth/invalid-email":
          message = "Invalid email format.";
          break;
        case "auth/weak-password":
          message = "Password is too weak.";
          break;
        default:
          message = "An error occurred. Please try again.";
          break;
      }
      setValidationMessage(message);
    } finally {
      setLoading(false);
    }
  }, [email, firstName, password, confirmPassword]);
  

  const updateUserDataAfterSignUp = useCallback(
    async (email, players) => {
      console.log("Starting updateUserDataAfterSignUp for email:", email);

      const currentUserUid = auth.currentUser?.uid;
      console.log("currentUserUid:", currentUserUid);

      const playerName = players.length > 0 ? players[0]?.name : "";
      const mainGameRef = collection(firestore, "MainGame");

      try {
        const querySnapshot = await getDocs(mainGameRef);
        for (const docSnapshot of querySnapshot.docs) {
          const gameId = docSnapshot.id;
          const gameData = docSnapshot.data();

          if (!gameData) {
            console.log("Game data not found for gameId:", gameId);
            continue; // Use continue to skip this iteration of the loop
          }

          // Update in 'Players' Subcollection
          const theGameRef = collection(
            firestore,
            "MainGame",
            gameId,
            "TheGame"
          );
          const theGameSnapshot = await getDocs(theGameRef);

          for (const theGameDoc of theGameSnapshot.docs) {
            const playersRef = collection(
              firestore,
              "MainGame",
              gameId,
              "TheGame",
              theGameDoc.id,
              "Players"
            );
            const playersSnapshot = await getDocs(playersRef);

            for (const playerDoc of playersSnapshot.docs) {
              const playerData = playerDoc.data();

              if (playerData.email === email) {
                const playerDocRef = doc(
                  firestore,
                  "MainGame",
                  gameId,
                  "TheGame",
                  theGameDoc.id,
                  "Players",
                  playerDoc.id
                );
                console.log(
                  `Attempting to update document at path: ${playerDocRef.path}`
                );

                await updateDoc(playerDocRef, {
                  id: currentUserUid, // Update with the current user's UID
                  userId: currentUserUid, // Update with the current user's UID
                  Name: playerName, // Update with the current user's name
                })
                  .then(() => {
                    console.log(
                      `Successfully updated player document for ${playerName} with UID ${currentUserUid}`
                    );
                  })
                  .catch((error) => {
                    console.error(`Failed to update player document: ${error}`);
                  });
              }
            }
          }

          // Step 2: Update 'memberOfGame'
          const updatedMembers = gameData.memberOfGame?.map((member) =>
            member.email === email
              ? {
                  ...member,
                  uid: currentUserUid,
                  name: playerName,
                  role: "verified",
                }
              : member
          );

         
            await updateDoc(doc(firestore, "MainGame", gameId), {
              memberOfGame: updatedMembers,
            });
         
        }
      } catch (error) {
        console.error("Error updating user data in games:", error);
      }
    },
    [auth, firestore]
  );

  return (
    <ImageBackground
      source={require("../../assets/background.jpg")}
      style={styles.backgroundImage}
    >
      <SafeAreaView style={styles.container}>
        <KeyboardAvoidingView
          behavior={Platform.select({
            ios: "padding",
            android: "height",
            web: "height",
          })}
          style={styles.keyboardAvoidingView}
        >
          <ScrollView contentContainerStyle={styles.innerContainer}>
            <Text style={styles.title}>Sign-up for TeamPlay</Text>
         

            <View style={styles.inputContainer}>
              <MaterialIcons name="person" size={24} color="white" />
              <TextInput
                style={[
                  styles.inputStyle,
                  inputFocus.firstName && styles.inputFocused,
                ]}
                placeholder="First Name"
                placeholderTextColor="white"
                value={firstName}
                onChangeText={setFirstName}
                onFocus={() => handleFocus("firstName")}
                onBlur={() => handleBlur("firstName")}
              />
            </View>

            <View style={styles.inputContainer}>
              <MaterialIcons name="email" size={24} color="white" />
              <TextInput
                style={[
                  styles.inputStyle,
                  inputFocus.email && styles.inputFocused,
                ]}
                placeholder="Email"
                placeholderTextColor="white"
                value={email}
                onChangeText={setEmail}
                keyboardType="email-address"
                autoCapitalize="none"
                onFocus={() => handleFocus("email")}
                onBlur={() => handleBlur("email")}
              />
            </View>
            
            <View style={styles.inputContainer}>
              <MaterialIcons name="lock" size={24} color="white" />
              <TextInput
              style={[styles.inputStyle, inputFocus.password && styles.inputFocused]}

                placeholder="Password"
                placeholderTextColor="white"
                secureTextEntry={true}
                value={password}
                onChangeText={setPassword}
                textContentType={Platform.OS === "ios" ? "oneTimeCode" : "none"} // An alternative to avoid strong password suggestions
                onFocus={() => handleFocus('password')}
                onBlur={() => handleBlur('password')}

                // textContentType="newPassword" // iOS suggests a strong password for new account sign-ups
              />
            </View>
            <View style={styles.inputContainer}>
              <MaterialIcons name="lock-outline" size={24} color="white" />
              <TextInput
              style={[styles.inputStyle, inputFocus.confirmPassword && styles.inputFocused]}
                placeholder="Confirm Password"
                placeholderTextColor="red"
                secureTextEntry={true}
                value={confirmPassword}
                onChangeText={setConfirmPassword}
                textContentType={Platform.OS === "ios" ? "oneTimeCode" : "none"} // Using "oneTimeCode" as a workaround
                onFocus={() => handleFocus('confirmPassword')}
                onBlur={() => handleBlur('confirmPassword')}

                //textContentType={Platform.OS === 'ios' ? "newPassword" : "none"} // Use newPassword for iOS to match the password field
              />
            </View>
            {validationMessage !== "" && (
              <Text style={styles.error}>{validationMessage}</Text>
            )}
            {loading ? (
              <ActivityIndicator size={50} color="#0000ff" />
            ) : (
              <Button
                title="SignUp"
                buttonStyle={styles.button}
                onPress={createAccount}
              />
            )}
            <TouchableOpacity onPress={() => navigation.navigate("SignIn")}>
              <Text style={styles.signUpText}>
                Already have an account? Login here!
              </Text>
            </TouchableOpacity>
          </ScrollView>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  backgroundImage: {
    flex: 1,
    width: "100%", // Make sure it covers the whole screen width
    height: "100%", // Make sure it covers the whole screen height
  },
  container: {
    flex: 1,
    backgroundColor: "transparent", // Ensure container is transparent to show the background image
  },
  keyboardAvoidingView: {
    flex: 1,
    width: "100%",
  },
  innerContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingTop: 20,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
    color: "white", // If you also want the title color to be white
  },
  logo: {
    width: 200,
    height: 200,
    marginBottom: 30,
  },
  // inputContainer: {
  //   flexDirection: "row",
  //   alignItems: "center",
  //   width: Platform.OS === "ios" ? "80%" : "30%",
  //   marginVertical: 12,
  //   borderBottomWidth: 1,
  //   borderColor: "gray", // Consider changing this if you want the border to be more visible on the background
  //   paddingVertical: 10,
  // },
  button: {
    marginTop: 20,
    paddingHorizontal: 20,
  },
  inputStyle: {
    marginLeft: 10,
    flex: 1,
    fontSize: 16,
    color: "white", // Here's the update for the text color
  },
  error: {
    color: "red",
    marginTop: 10,
    fontSize: 18,
    fontWeight: "bold",
    //backgroundColor: 'rgba(0,0,0,0.6)',
    backgroundColor: "white",
  },
  signUpText: {
    marginTop: 20,
    color: "white",
    backgroundColor: 'rgba(0,0,0,0.6)',

  },
  signUpText: {
    marginTop: 20,
    color: "#000000",
    fontSize: 16,

    backgroundColor: "#D3D3D3",
  },
 



   // Existing styles remain unchanged
   inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "90%", // Keep the responsive width
    maxWidth: 400, // Add a maxWidth to prevent the fields from becoming too wide on screens
    marginVertical: 12,
    borderBottomWidth: 1,
    borderColor: "gray",
    paddingVertical: 10,
    alignSelf: 'center', // Ensure the container is centered within its parent
  },
 
inputStyle: {
  marginLeft: Platform.select({ ios: 0, android: 10, web: 10 }), // Adjust marginLeft based on platform, including a specific style for web
  flex: 1,
  fontSize: 16,
  color: "white",
},
  inputFocused: {
    borderColor: "#E6E6FA",
    borderWidth: 2,
    padding: 8, // Consider if additional padding is needed for focused state
    backgroundColor: '#9D9B9A', // A light grey color

  },
  



});

export default SignUpScreen;
