import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image } from 'react-native';
import { setDoc, doc } from 'firebase/firestore';
import { database } from '../componenets/firebase'; // adjust import as per your structure






const gameModes = [
    // { name: 'individual', imgSrc: require('../assets/Individual.png')},
    { name: 'team',  imgSrc: require('../assets/Team.png')},
    // { name: 'beerCounter',  imgSrc: require('../assets/Beer_Counter.png')},
   //  { name: 'knockOut',  imgSrc: require('../assets/KnockOut.png')},
  ];

function GameModeSelector({ onModeSelect }) {
  const [selectedMode, setSelectedMode] = useState(null);

  const selectMode = (mode) => {
    setSelectedMode(mode);
    onModeSelect(mode);
  };


  return (
    <View style={styles.container}>
      {gameModes.map((mode, index) => (
        <TouchableOpacity key={index} onPress={() => selectMode(mode.name)}>
          <Image source={mode.imgSrc} style={styles.modeImage} />
          {selectedMode === mode.name && <Text style={styles.checkmark}>✓</Text>}
        </TouchableOpacity>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap' // for wrapping to next line in case of many items
  },
  modeImage: {
    width: 100,
    height: 100,
    margin: 10,
    borderRadius: 10
  },
  checkmark: {
    position: 'absolute',
    color: 'white', // Change to white for better visibility
    fontSize: 50, // Increase size
    top: '50%',
    left: '50%',
    transform: [{ translateX: -25 }, { translateY: -25 }],
    backgroundColor: 'rgba(0, 128, 0, 0.6)', // Semi-transparent green circular background
    borderRadius: 25, // Make it circular, half of fontSize
    width: 50, // Equal to fontSize
    height: 50, // Equal to fontSize
    textAlign: 'center', // To center the checkmark inside the circle
  },
  

  modeImage: {
    width: 150,
    height: 150,
    margin: 10,
    borderRadius: 15
  },
  


});

export default GameModeSelector;