import React, { useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Button,
  ImageBackground,
  TouchableOpacity,
  Image,
  ScrollView,
  SafeAreaView,
  Linking,
  Pressable,
  TextInput,
  Alert 

  
} from 'react-native';
import { database } from '../componenets/firebase'; // Ensure correct path
import { collection, addDoc } from 'firebase/firestore';


import { useNavigation } from '@react-navigation/native';

import * as WebBrowser from 'expo-web-browser';
import yourYoutubeImage from '../assets/YoutubeImg.jpg'; // replace with your actual image path

const Home = () => {
  const navigation = useNavigation();
  const backgroundImage = require('../assets/background.jpg');

  const openYoutube = async () => {
    await WebBrowser.openBrowserAsync('https://youtu.be/X3pmh7QJFeA'); // replace with your YouTube video link
  };

  const [email, setEmail] = useState('');


  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSaveEmail = async () => {
    if (!validateEmail(email)) {
      Alert.alert('Invalid Email', 'Please enter a valid email address.');
      return;
    }

    try {
      await addDoc(collection(database, 'tester'), {
        email: email,
      });
      Alert.alert('Success', 'Email saved successfully');
      setEmail(''); // Clear the input field
    } catch (error) {
      Alert.alert('Error', 'Failed to save email');
      console.error('Error adding document: ', error);
    }
  };



  
  return (
    <SafeAreaView style={styles.container}>
      <ImageBackground source={backgroundImage} style={styles.background} resizeMode="cover">
        <ScrollView contentContainerStyle={styles.scrollView}>
          <View style={styles.header}>
            <Text style={styles.title}>Welcome to TeamPlay.cool</Text>
            {/* <Text style={styles.subtitle}>Beta version</Text> */}

            {/* <Text style={styles.label}>Become Tester Enter Your Email:</Text>
      <TextInput
        style={styles.input}
        value={email}
        onChangeText={setEmail}
        placeholder="Email"
        keyboardType="email-address"
        autoCapitalize="none"
      />
      <Button title="Save Email" onPress={handleSaveEmail} /> */}



            <View style={styles.buttons}>
              <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Sign Up')}>
                <Text style={styles.buttonText}>Sign Up (First Time)</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Sign In')}>
                <Text style={styles.buttonText}>Log In</Text>
              </TouchableOpacity>
            </View> 

 
            <View style={styles.youtubeContainer}>
            <Text style={styles.youtubeText}>Tap the image to watch our introduction</Text>
            <TouchableOpacity onPress={openYoutube}>
              <Image source={yourYoutubeImage} style={styles.youtubeImage} />
            </TouchableOpacity>

           

            </View>


          </View>
        </ScrollView>
      </ImageBackground>
    </SafeAreaView>
  );
};

export default Home;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  background: {
    flex: 1,
  },
  scrollView: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    alignItems: 'center',
    margin: 20,
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    marginTop: 20,
    //backgroundColor: 'rgba(0,0,0,0.5)',
    padding: 10,
    borderRadius: 10,
  },
  subtitle: {
    fontSize: 20,
    color: '#FFD700', // Gold color for emphasis
    marginVertical: 10,
  },
  buttons: {
    marginTop: 20,
  },
  button: {
    backgroundColor: '#007BFF', // Bootstrap primary button color
    padding: 12,
    borderRadius: 8,
    marginTop: 10,
    width: 250, // Fixed width for consistency
    alignItems: 'center', // Center text horizontally
  },
  buttonText: {
    color: 'white',
    fontSize: 18,
  },

  youtubeContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
  },


  youtubeText: {
    //marginTop : 20, 
    marginVertical: 20,
    fontSize: 16,
    color: 'white',
    textAlign: 'center',
    backgroundColor: 'rgba(0,0,0,0.6)',
    padding: 10,
    borderRadius: 8,
  },
  youtubeImage: {
    width: 300, //  
    height: 200, // Adjusted for aspect ratio
    borderRadius: 10, // Soften the edges
  },

  label: {
    fontSize: 18,
    marginBottom: 10,
    color: 'white',
  },
  input: {
    height: 40,
    borderColor: 'black',
    borderWidth: 1,
    marginBottom: 20,
    paddingHorizontal: 40,
    color: 'white',
  },


});