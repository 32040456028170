import { doc, updateDoc, arrayUnion, getDoc, setDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";

const firestore = getFirestore();

/**
 * Adds the user to the specified game using the hard-coded game ID.
 *
 * @param {string} userId - The user's unique ID from Firebase auth.
 * @param {string} userName - The user's name (optional, default is "New Player").
 * @param {string} email - The user's email.
 */
export async function joinDemoGame(userId, userName = "New Player", email) {
  const gameId = "IVKgDlA9YCVGJN19m8hx"; // Hard-coded game ID

  try {
    const gameDocRef = doc(firestore, "MainGame", gameId);
    const gameDocSnap = await getDoc(gameDocRef);

    if (!gameDocSnap.exists()) {
      // If the game doc doesn't exist, create it
      await setDoc(gameDocRef, {
        memberOfGame: [
          {
            uid: userId,
            name: userName,
            email: email,
            isAdmin: true, // Added field

            role: "verified",
            joinedAt: new Date(),
          },
        ],
      });
      console.log("Game created and user added!");
      return;
    }

    const gameData = gameDocSnap.data() || {};
    const memberOfGame = gameData.memberOfGame || [];

    // Add the user if not already in the list
    const isAlreadyMember = memberOfGame.some((member) => member.uid === userId);
    if (!isAlreadyMember) {
      await updateDoc(gameDocRef, {
        memberOfGame: arrayUnion({
          uid: userId,
          name: userName,
          email: email,
          isAdmin: true, // Added field

          role: "verified",
          joinedAt: new Date(),
        }),
      });
      console.log("User added to the game!");
    } else {
      console.log("User is already a member of the game.");
    }
  } catch (error) {
    console.error("Error adding user to the game:", error);
  }
}
