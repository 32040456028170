import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Switch,
  Alert,
  StyleSheet,
  ScrollView,
  Linking,

} from "react-native";
import * as Notifications from "expo-notifications";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const db = getFirestore();

const NotificationSettings = () => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [pushToken, setPushToken] = useState("");
  const [deviceInfo, setDeviceInfo] = useState({ brand: "", model: "" });
  const [userEmail, setUserEmail] = useState("");
  const userId = getAuth().currentUser?.uid;

  useEffect(() => {
    fetchUserData();
    checkNotificationPermissions();

    const user = getAuth().currentUser;
    if (user) {
      setUserEmail(user.email);
    }
  }, []);

  const fetchUserData = async () => {
    if (userId) {
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const data = userDoc.data();
        setPushToken(data.expoPushToken || "No token found");
        setDeviceInfo({
          brand: data.deviceBrand || "Unknown brand",
          model: data.deviceModel || "Unknown model",
        });
      }
    }
  };

  const checkNotificationPermissions = async () => {
    const { status } = await Notifications.getPermissionsAsync();
    setIsEnabled(status === "granted");
  };


  const toggleSwitch = async () => {
    if (!isEnabled) {
      const { status } = await Notifications.requestPermissionsAsync();
      if (status !== "granted") {
        Alert.alert(
          "Permission Required",
          "Please enable notifications in your settings.",
          [
            { text: "Cancel", style: "cancel" },
            {
              text: "Open Settings",
              onPress: () => Linking.openSettings(),
            },
          ]
        );
        return;
      }
    }
    const newStatus = !isEnabled;
    setIsEnabled(newStatus);
    handleNotificationPreference(newStatus);
  };




  



  const handleNotificationPreference = async (enabled) => {
    try {
      if (userId) {
        const userDocRef = doc(db, "users", userId);
        await updateDoc(userDocRef, { notification: enabled });
      }

      if (enabled) {
        await Notifications.scheduleNotificationAsync({
          content: {
            title: "Notifications Enabled",
            body: "You will receive notifications.",
          },
          trigger: { seconds: 1 },
        });
      } else {
        await Notifications.cancelAllScheduledNotificationsAsync();
      }
    } catch (error) {
      console.error("Error updating notification preference:", error);
    }
  };

  return (
    <ScrollView style={styles.container}>
      <Text style={styles.header}>Notification Settings</Text>
      <Text style={styles.description}>
        Stay updated with the latest game results and get reminders for upcoming
        matches. Enable notifications to never miss out!
      </Text>
      <View style={styles.switchContainer}>
        <Text style={styles.label}>Enable Notifications</Text>
        <Switch
          trackColor={{ false: "#B0B0B0", true: "#34D399" }}
          thumbColor={isEnabled ? "#FFFFFF" : "#f4f3f4"}
          ios_backgroundColor="#3e3e3e"
          onValueChange={toggleSwitch}
          value={isEnabled}
        />
      </View>
      <View style={styles.infoContainer}>
        <Text style={styles.label}>
          Device Brand: <Text style={styles.boldText}>{deviceInfo.brand}</Text>
        </Text>
        <Text style={styles.label}>
          Device Model: <Text style={styles.boldText}>{deviceInfo.model}</Text>
        </Text>
        {userEmail === "dondanni@hotmail.com" && (
          <Text style={styles.label}>
            Expo Push Token:{" "}
            <Text style={styles.boldText}>
              {pushToken || "Can't fetch token..."}
            </Text>
          </Text>
        )}
      </View>
    </ScrollView>
  );
};

export default NotificationSettings;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingTop: 30,
    backgroundColor: "#F9FAFB",
    flex: 1,
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#1F2937",
    marginBottom: 12,
    textAlign: "center",
  },
  description: {
    fontSize: 16,
    color: "#4B5563",
    textAlign: "center",
    marginBottom: 20,
    lineHeight: 22,
  },
  switchContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 15,
    paddingHorizontal: 25,
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 3,
    marginBottom: 25,
  },
  infoContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    padding: 20,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 3,
  },
  label: {
    fontSize: 16,
    color: "#1F2937",
    marginBottom: 10,
  },
  boldText: {
    fontWeight: "bold",
    color: "#111827",
  },
});
