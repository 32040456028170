import React, { useEffect, useState, useRef } from 'react';
import * as Notifications from 'expo-notifications';
import 'react-native-gesture-handler';
import { useAuthentication } from '../hook/useAuthentication';
import Navigation from './Navigation';
import TabNavigator2 from './authStack';
import { getExpoPushToken, scheduleSpecificNotification } from './Notification';
//import { logEvent, getAnalytics } from 'firebase/analytics';
import { Platform } from 'react-native';

export default function RootNavigation() {
  const { user } = useAuthentication();
  const [expoPushToken, setExpoPushToken] = useState('');
  const routeNameRef = useRef();
  const navigationRef = useRef();

  // useEffect(() => {
  //   // Retrieve the Expo push token and set it in the state
  //   const fetchToken = async () => {
  //     const token = await getExpoPushToken();
  //     setExpoPushToken(token);
  //     console.log('Expo Push Token:', token);
  //     if (token) {
  //       await scheduleSpecificNotification(token); // Schedule notification after retrieving the token
  //     }
  //   };

  //   fetchToken();
  // }, []);

  useEffect(() => {
    // Listener for handling notifications when the app is in the foreground
    const subscription = Notifications.addNotificationResponseReceivedListener(response => {
      console.log('Notification response received:', response);
      // Additional handling if needed, like navigation
    });

    // Clean up the listener on unmount
    return () => subscription.remove();
  }, []);

  // const trackScreenView = (screenName) => {
  //   if (Platform.OS !== 'ios' && Platform.OS !== 'android') {
  //     const analytics = getAnalytics();
  //     logEvent(analytics, 'screen_view', {
  //       screen_name: screenName,
  //       screen_class: screenName,
  //     });
  //     console.log(`Screen view logged: ${screenName}`);
  //   }
  // };

  // const handleNavigationStateChange = () => {
  //   const previousRouteName = routeNameRef.current;
  //   const currentRouteName = navigationRef.current.getCurrentRoute().name;

  //   if (previousRouteName !== currentRouteName) {
  //     // Log the screen view with the new route name
  //     trackScreenView(currentRouteName);
  //   }

  //   // Save the current route name for future comparison
  //   routeNameRef.current = currentRouteName;
  // };

  return user ? (
    <Navigation
      navigationRef={navigationRef}
      routeNameRef={routeNameRef}
     // onStateChange={handleNavigationStateChange}
    />
  ) : (
    <TabNavigator2 />
  );
}
