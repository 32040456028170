import React, { useEffect, useState } from 'react';
import { ActivityIndicator, ScrollView, View, Text, StyleSheet, Alert, Platform, Image, Pressable,SafeAreaView } from 'react-native';
import { doc, getDoc, deleteDoc, collection, getDocs, query, where, updateDoc } from 'firebase/firestore';
import { getStorage, ref, deleteObject, getDownloadURL } from 'firebase/storage';
import { database } from '../componenets/firebase'; // Ensure this path is correct
import { auth } from '../componenets/firebase'; // Assuming you're using Firebase Auth
import moment from 'moment';

//import AccountSettings from '../screens/AccountSettings';
//import NotificationSettings from '../screens/NotificationSettings';


const AccountSettings = () => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);  // Start with loading state true



    useEffect(() => {
      const fetchCurrentUserAndGames = async () => {
        try {
            const currentUser = auth.currentUser; // Assuming you're using Firebase Auth
            if (currentUser) {
                const userDocRef = doc(database, "users", currentUser.uid);
                const userSnapshot = await getDoc(userDocRef);
                if (userSnapshot.exists()) {
                    let userData = {
                        id: userSnapshot.id,
                        ...userSnapshot.data(),
                        games: []
                    };
    
                    // Fetch all games initially to check data
                    const gamesSnapshot = await getDocs(collection(database, "MainGame"));
                   // console.log("All games fetched:", gamesSnapshot.docs.map(doc => ({id: doc.id, ...doc.data()})));
                    
                    for (const gameDoc of gamesSnapshot.docs) {
                      const theGamesSnapshot = await getDocs(collection(database, "MainGame", gameDoc.id, "TheGame"));
                      for (const theGameDoc of theGamesSnapshot.docs) {
                          // Assuming 'Players' is a subcollection under 'TheGame' and 'userId' is the document
                          const playerDocRef = doc(database, "MainGame", gameDoc.id, "TheGame", theGameDoc.id, "Players", currentUser.uid);
                          const playerDocSnapshot = await getDoc(playerDocRef);
                          if (playerDocSnapshot.exists()) {
                              // Now check if 'id' field (or any relevant field) matches the condition you're looking for
                              const playerId = playerDocSnapshot.data().id;  // Access the 'id' field
                              if (playerId === currentUser.uid) {  // Check if this player ID matches the current user ID
                                

                                  const gameDetails = {
                                      gameId: gameDoc.id,
                                      theGameId: theGameDoc.id,
                                      gameName: gameDoc.data().name,
                                      theGameDetails: theGameDoc.data(),
                                      playerDetails: playerDocSnapshot.data()
                                  };
                                  userData.games.push(gameDetails);
                  
                                  // Log the details of the player for this game
                                 // console.log("Game Player Details:", gameDetails.playerDetails);
                              }
                          }
                      }
                  }
                  
                  //console.log("User Data with Games:", userData);
                  setUser(userData);
                  
                } else {
                   // console.log("No such user!");
                }
            }
        } catch (error) {
          //  console.error("Failed to fetch user and games:", error);
        }
        setIsLoading(false);  // Stop loading after all fetches complete
    };

    fetchCurrentUserAndGames();
}, []);


 
const deleteAllUserData = async () => {
  const userId = auth.currentUser?.uid;
  if (!userId) {
      Alert.alert("Error", "No user ID found. Cannot delete user data.");
      return;
  }

  const confirmDeletion = async () => {  // Ensure this function is marked async
      // console.log("Starting the deletion of all user data for userId:", userId);
      
      try {
          const storage = getStorage();
          const imagePath = `/userProfile/${userId}/profile-picture`;
          const imageRef = ref(storage, imagePath);
          await deleteObject(imageRef).catch(error => console.log("Error deleting profile picture:", error));

          const userDocRef = doc(database, "users", userId);
          await deleteDoc(userDocRef);  // Async operation, so await is required

          const gamesSnapshot = await getDocs(collection(database, "MainGame"));  // This needs to be inside an async function
          gamesSnapshot.forEach(async (doc) => {
              const gameData = doc.data();
              if (gameData.memberOfGame.some(member => member.uid === userId)) {
                  const updatedMembers = gameData.memberOfGame.filter(member => member.uid !== userId);
                  await updateDoc(doc.ref, { memberOfGame: updatedMembers });
                  // console.log(`User removed from memberOfGame for game ${doc.id}:`, updatedMembers);
              }
          });

          Alert.alert("All Data Deleted", "All data related to the user has been successfully deleted.");
          setUser(null); // Clear user from state after all deletions
      } catch (error) {
          console.error("Failed to delete all user data:", error);
          Alert.alert("Deletion Error", "Failed to delete all user data.");
      }
  };

  if (Platform.OS === "web") {
      if (window.confirm("Are you sure you want to delete this user? This action cannot be undone.")) {
          confirmDeletion();  // This is an async function now
      }
  } else {
      Alert.alert(
          "Confirm Deletion",
          "Are you sure you want to delete this user? This action cannot be undone.",
          [
              { text: "Cancel", onPress: () => console.log("Deletion cancelled"), style: "cancel" },
              { text: "Delete", onPress: confirmDeletion, style: "destructive" }  // Also here, handle as async
          ],
          { cancelable: false }
      );
  }
};

return (
    <SafeAreaView style={styles.safeArea}>
        <ScrollView>
        <View style={styles.container}>
        <Pressable onPress={() => deleteAllUserData()} style={styles.deleteButton}>
            <Text style={styles.deleteButtonText}>Delete Account</Text>
        </Pressable>
        {isLoading ? (
            <View style={styles.loadingContainer}>
                <ActivityIndicator size={50} color="#0000ff" />
                <Text style={styles.loadingText}>Fetching profile information might take a moment.</Text>
            </View>
        ) : user ? (
                        <>
                            <View style={styles.userItem}>
                                <Image
                                    source={{ uri: user.avatarUri }}
                                    resizeMode="cover"
                                    style={styles.avatar}
                                />
                                <View>
                                    <Text style={styles.userInfo}>Name: {user.name}</Text>
                                    <Text style={styles.userInfo}>Email: {user.email}</Text>
                                    <Text style={styles.userInfo}>User ID:</Text>
                                    <Text style={styles.userInfo}>{user.id}</Text>
                                </View>
                            </View>                     
                        </>
                    ) : (
                        <Text>No user data available</Text>
                )}
                 {user && user.games.length > 0 ? (
                    user.games.map(game => (
                        <View key={game.theGameId} style={styles.gameDetails}>
                            <Text style={styles.gameName}>Game Name: {game.gameName}</Text>
                            <Text style={styles.gameInfo}>Date: {moment(game.playerDetails.date).format('MMMM DD, YYYY')}</Text>
                            <Text style={styles.gameInfo}>Game ID: {game.gameId}</Text>
                           
                            
                            <Text style={styles.gameInfo}>User Name: {game.playerDetails.Name}</Text>
                            <Text style={styles.gameInfo}>User Results: {game.playerDetails.Result}</Text>
                            <Text style={styles.gameInfo}>Points: {game.playerDetails.totalPoints}</Text>
                        </View>
                    ))
                ) : user ? (
                    <Text style={styles.gameInfo}>No games found for user.</Text>
                ) : null}
                 {/* {user && (
                    <Pressable onPress={() => deleteAllUserData()} style={styles.deleteButton}>
                        <Text style={styles.deleteButtonText}>Delete Account</Text>
                    </Pressable>
                )}  */}
                 
            </View>
        </ScrollView>
    </SafeAreaView>
);
};



const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        backgroundColor: '#f0f0f0'
    },
    container: {
       // flex: 1,
        padding: 20,
       // backgroundColor: '#f0f0f0',
    },
    userItem: {
        flexDirection: 'row',  // Align items in a row
        alignItems: 'center',  // Center items vertically
        backgroundColor: '#ffffff',
        padding: 15,
        borderRadius: 10,
        marginBottom: 10,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 3,
    },
    avatar: {
        width: 100,
        height: 100,
        borderRadius: 50,  // Circular avatar
        marginRight: 15,   // Space between avatar and text
    },
    userInfo: {
        fontSize: 18,
        marginBottom: 5,
        color: '#333',
        fontWeight: 'bold',
    },
    gameDetails: {
        backgroundColor: '#ffffff',
        padding: 10,
        marginTop: 5,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#ddd',
    },
    gameName: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 4,
    },
    gameInfo: {
        fontSize: 16,
        color: '#666',
        marginBottom: 4,
        paddingLeft: 10,
    },
    deleteButton: {
        backgroundColor: '#ff6347',
        padding: 10,
        borderRadius: 5,
        marginTop: 20,
    },
    deleteButtonText: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },

    scrollView: {
        flexGrow: 1  // Ensures the content grows to fit
    },

    loadingContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
    },
    loadingText: {
        marginTop: 10,
        fontSize: 16,
        color: '#0000ff',
    },


});


export default AccountSettings;